import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { parse } from 'query-string'

import LoginPage from 'pages/Login/Loadable'
import SignUpPage from 'pages/SignUp/Loadable'
import ForgotPasswordPage from 'pages/ForgotPassword/Loadable'
import ResetPasswordPage from 'pages/ResetPassword/Loadable'
import AccountPayment from 'pages/AccountPayment/Loadable'
import EnrollPage from 'pages/Enroll/Loadable'
import MaintenancePage from 'pages/Maintenance/Loadable'
import RedirectPage from 'pages/Redirect/Loadable'

import PrivateRoute from 'components/Routes/PrivateRoute'

import * as actions from 'modules/app/actions'
import { selectIsWebView, selectSwitchMeter } from 'modules/app/selectors'
import { makeSelectUserID } from 'modules/user/selectors'

import injectSaga from 'utils/injectSaga'
import zendesk from 'utils/zendesk'

import saga from './saga'
import { Dashboard, Main } from './styled'
import Header from './Header'
import Sidebar from './Sidebar'
import FullDashboard from './FullDashboard'
import { url } from 'config/profile'
import { isMobileWebview } from 'utils/utils'

export class App extends Component {
  mainContainer = React.createRef()

  componentWillMount() {
    const { location, isWebView, setWebView } = this.props
    const { client } = parse(location.search)
    const webViewClients = ['iOS', 'Android']
    if (!isWebView && webViewClients.includes(client)) {
      setWebView(client)
    } else if (!isWebView && isMobileWebview) {
      setWebView('mobileWebview')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.mainContainer.current) {
        this.mainContainer.current.scrollTo(0, 0)
      }
    }
  }

  render() {
    const {
      isWebView,
      switchMeter,
      renderingDashboard,
      userId,
    } = this.props
    return (
      <>
        {!isWebView && <Header />}
        {process.env.REACT_APP_MAINTENANCE_MODE === 'true' && (
          <MaintenancePage />
        )}
        {process.env.REACT_APP_MAINTENANCE_MODE !== 'true' && (
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            {userId === '' ? (
              <Route
                path="/sign-up"
                component={() => {
                  const redirectSignUpTo = url.SIGN_UP
                  window.location.href = redirectSignUpTo
                  return null
                }}
              />
            ) : (
              <Route path="/sign-up" component={SignUpPage} />
            )}
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/reset-password-link" component={ResetPasswordPage} />
            <Route path="/account-payment" component={AccountPayment} />
            <Route path="/redirect" component={RedirectPage} />
            <PrivateRoute
              path="/"
              render={({ location }) => {
                if (isMobileWebview) {
                  zendesk.hide()
                } else {
                  zendesk.show()
                }

                return (
                  <Dashboard id="dashboard">
                    <Switch>
                      <Route path="/(add-meter|enroll)/" component={null} />
                      {!isMobileWebview && (
                        <Route
                          render={() => (
                            <Sidebar />
                          )}
                        />
                      )}
                    </Switch>
                    <Main id="main" innerRef={this.mainContainer}>
                      <Switch>
                        <Route path="/enroll" component={EnrollPage} />
                        <Route
                          render={() => {
                            renderingDashboard()
                            return (
                              <FullDashboard
                                switchMeter={switchMeter}
                                isWebView={Boolean(isWebView)}
                              />
                            )
                          }}
                        />
                      </Switch>
                    </Main>
                  </Dashboard>
                )
              }}
            />
          </Switch>
        )}
      </>
    )
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  isWebView: PropTypes.bool.isRequired,
  setWebView: PropTypes.func.isRequired,
  switchMeter: PropTypes.object,
  renderingDashboard: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

const mapStateToProps = createStructuredSelector({
  isWebView: selectIsWebView,
  switchMeter: selectSwitchMeter,
  userId: makeSelectUserID(),
})

export default compose(
  injectSaga({ key: 'app', saga }),
  withRouter,
  connect(
    mapStateToProps,
    {
      setWebView: actions.setWebView,
      renderingDashboard: actions.renderingDashboard,
    }
  )
)(App)
