import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Text } from 'components/text'
import { selectImpersonatedBanner } from 'modules/app/selectors'

import {
  ImpersonateSection,
  ImpersonateSectionDescription,
  ImpersonateDescription,
} from './styled'

const ImpersonateBanner = ({
  impersonatedBanner,
}) => {
  const [visible, setVisible] = useState(true)
  const ref = useRef(null)

  useEffect(() => {
    setVisible(impersonatedBanner)
  }, [impersonatedBanner])

  return (
    <div ref={ref}>
      {visible && (
        <ImpersonateSection>
          <ImpersonateSectionDescription>
            <ImpersonateDescription>
              <Trans>
                <Text width="100%" color={'#FFFFFF'}>
                  The session is an impersonated session
                </Text>
              </Trans>
            </ImpersonateDescription>
          </ImpersonateSectionDescription>
        </ImpersonateSection>
      )}
    </div>
  )
}

ImpersonateBanner.propTypes = {
  billingAddress: PropTypes.object,
  locale: PropTypes.string,
  latestProduct: PropTypes.object,
  accountNumber: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  impersonatedBanner: selectImpersonatedBanner,
})

export default connect(mapStateToProps)(ImpersonateBanner)
