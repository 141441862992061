import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { createStructuredSelector } from 'reselect'

import { Trans } from '@lingui/macro'
import { Aside, StyledLink, Label } from './styled'
import {
  makeSelectOffers,
  makeSelectHideRenewalPage,
} from 'pages/Renewal/selectors'
import { hideRenewalPage } from 'pages/Renewal/actions'
import appUrls from '../urls'

const Sidebar = ({
  offers,
  toggleRenewalPage,
  hideRenewalPage,
}) => {
  const [urls, setUrls] = useState(appUrls)

  useEffect(() => {
    // hide renewal offer page on sidebar for 24hr after submit date
    const item = localStorage.getItem('renewal_offer_submit_date')
    if (item) {
      let now = new Date().getTime()
      const msBetweenDates = Math.abs(item - now)
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)
      if (hoursBetweenDates < 24) {
        toggleRenewalPage(true)
      } else {
        toggleRenewalPage(false)
        localStorage.removeItem('renewal_offer_submit_date')
      }
    } else {
      toggleRenewalPage(false)
    }
  }, [])

  useEffect(() => {
    // enable renewal page based on renewale offers availability
    let tmp = appUrls
      .map(key => {
        if (key.label === 'Renewal') {
          key.isPermitted = offers.length > 0 && hideRenewalPage === false
        }
        return key
      })
      .filter(url => {
        return !url.hideOnDesktop
      })
    setUrls(tmp)
  }, [offers, hideRenewalPage])

  return (
    <Aside data-testid="sideBar">
      {urls.map(({  label, to, exact, Icon, isPermitted }) => {
        return isPermitted === false ? null : (
          <StyledLink
            data-testid={`sidebarLink:${label}`}
            key={to}
            to={to}
            exact={exact}
          >
            <Icon />
            <Label>
            <Trans id={label} />
            </Label>
          </StyledLink>
        )
      })}
    </Aside>
  )
}

Sidebar.propTypes = {
  offers: PropTypes.array.isRequired,
}

const mapStateToProps = createStructuredSelector({
  offers: makeSelectOffers(),
  hideRenewalPage: makeSelectHideRenewalPage(),
})

const mapDispatchToProps = {
  toggleRenewalPage: hideRenewalPage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Sidebar)
