import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, withStateHandlers, lifecycle } from 'recompose'
import { createStructuredSelector } from 'reselect'
import isEmpty from 'lodash.isempty'
import Media from 'react-media'
import { Flex } from 'grid-styled'
import { Trans } from '@lingui/macro'

import { CSSTransition } from 'react-transition-group'

import { breakpoints } from 'styles/mq'
import * as storage from 'utils/storage'

import { logout as logoutAction, showMessagesInbox } from 'modules/app/actions'
import { changeCurrentMember as changeCurrentMemberAction } from 'modules/members/actions'

import {
  selectNewMessage,
  selectShowMessagesInbox,
} from 'modules/app/selectors'
import { selectIsAuthenticated, selectFirstName } from 'modules/user/selectors'
import { selectEnrolledMembers } from 'modules/members/selectors'

import { TextSemi } from 'components/text'

import {
  StyledHeader,
  HeaderBackground,
  StyledLogo,
  NotificationButton,
  StyledWrapper,
} from './styled'
import MobileNav from './MobileNav'
import Hamburger from './MobileNav/Hamburger'
import Dropdown from './Dropdown'
import Newscard from './InAppMessages/Newscard'
import Bell from './InAppMessages/Icons/Bell'
import AlertBell from './InAppMessages/Icons/AlertBell'
import RenewalBanner from './RenewalBanner'
import PlusBanner from './PlusBanner'
import ImpersonateBanner from './ImpersonateBanner'

import { url, features } from 'config/profile'

let cancelTo

const CancelButton = () => (
  <Link data-testid="cancelButton" to={cancelTo || '/'}>
    <TextSemi color="#fff">
      <Trans>Cancel</Trans>
    </TextSemi>
  </Link>
)

const LinkLogo = () => (
  <a href={url.HOME_PAGE} target="_blank" rel="noopener noreferrer">
    <StyledLogo />
  </a>
)

const Logo = () => <StyledLogo />

const isMobileQuery = {
  maxWidth: breakpoints.tablet - 1,
}

const checkUnreadMessage = newMessage => {
  const lastReadMessage =
    JSON.parse(storage.getLastReadMessage('lastReadMessage')) || {}
  return newMessage.messageId !== lastReadMessage.messageId
}

const Header = ({
  name = '',
  members,
  isAuthenticated,
  logout,
  changeCurrentMember,
  location,
  toggleMobileNav,
  isMobileNavOpen,
  latestMessage,
  transitionCard,
  toggleCardTransition,
  toggleInbox,
  inboxOpen,
  newscardShown,
  toggleNewscard,
}) => {
  const [bannerHeight, setBannerHeight] = useState(null)
  // Uncomment to see newscard in absence of fresh campaign
  // storage.setLastReadMessage({ messageId: 'abc123' })
  const { state, pathname } = location
  if (state && state.cancelTo) {
    cancelTo = state.cancelTo
  }
  const displayInAppMessages = pathname === '/' && !isMobileNavOpen

  const newscardMessage = latestMessage
  const unreadMessage =
    newscardMessage && checkUnreadMessage(newscardMessage)
      ? newscardMessage
      : {}

  if (!isEmpty(unreadMessage) && !transitionCard) {
    toggleNewscard(true)
  }

  const setLastReadMessage = messageId => {
    storage.setLastReadMessage({ messageId })
  }

  const renderNotificationButton = () => (
    <NotificationButton
      onClick={toggleInbox}
      inboxOpen={inboxOpen}
      disabled={newscardShown}
    >
      {isEmpty(unreadMessage) ? <Bell /> : <AlertBell />}
    </NotificationButton>
  )

  const closeNewscard = () => {
    toggleNewscard(false)
    toggleCardTransition(true)
  }

  return (
    <>
      <StyledHeader>
        {features.RENEWAL_BANNER && (
          <RenewalBanner setBannerHeight={setBannerHeight} />
        )}
        {features.PLUS_BANNER && isAuthenticated && (
          <PlusBanner setBannerHeight={setBannerHeight} />
        )}
        {isAuthenticated && (
          <ImpersonateBanner setBannerHeight={setBannerHeight} />
        )}
        <HeaderBackground>
          <Media query={isMobileQuery}>
            {isMobile =>
              isMobile && isMobileNavOpen ? (
                <TextSemi ellipsis color="#fff">
                  {name ? (
                    <Trans>Welcome, {name}</Trans>
                  ) : (
                    <Trans>Welcome</Trans>
                  )}
                </TextSemi>
              ) : (
                <Switch>
                  <Route path="/(sign-up|login)/" component={LinkLogo} />
                  <Route component={Logo} />
                </Switch>
              )
            }
          </Media>
          {isAuthenticated && (
            <Switch>
              <Route path="/sign-up" component={null} />
              <Route path="/add-meter/thank-you" component={null} />
              <Route path="/enroll/thank-you" component={null} />
              <Route path="/(add-meter|enroll)/" component={CancelButton} />
              <Route
                path="/"
                render={() => (
                  <Media query={isMobileQuery}>
                    {isMobile =>
                      isMobile ? (
                        <Flex alignItems="center">
                          {displayInAppMessages && renderNotificationButton()}
                          <Hamburger
                            isOpen={isMobileNavOpen}
                            onClick={toggleMobileNav}
                          />
                        </Flex>
                      ) : (
                        <Flex alignItems="center">
                          <Dropdown
                            name={name}
                            members={members}
                            logout={logout}
                          />
                          {displayInAppMessages && renderNotificationButton()}
                        </Flex>
                      )
                    }
                  </Media>
                )}
              />
            </Switch>
          )}
        </HeaderBackground>
        <CSSTransition
          in={newscardShown}
          unmountOnExit
          timeout={2000}
          className="StyledWrapper"
          onExited={() => {
            setLastReadMessage(unreadMessage.messageId)
          }}
        >
          <StyledWrapper>
            {displayInAppMessages && (
              <Newscard
                isOpen={newscardShown}
                onClose={() => {
                  closeNewscard()
                }}
                message={unreadMessage}
              />
            )}
          </StyledWrapper>
        </CSSTransition>
      </StyledHeader>
      {isAuthenticated && (
        <Media
          query={isMobileQuery}
          render={() => (
            <MobileNav
              isOpen={isMobileNavOpen}
              onClick={toggleMobileNav}
              changeCurrentMember={changeCurrentMember}
              members={members}
              logout={logout}
              bannerHeight={bannerHeight}
            />
          )}
        />
      )}
    </>
  )
}

Header.propTypes = {
  name: PropTypes.string,
  members: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  changeCurrentMember: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  toggleMobileNav: PropTypes.func.isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
  newscardShown: PropTypes.bool,
}

const toggleMobileNav = ({ isMobileNavOpen }) => () => {
  if (isMobileNavOpen) {
    document.body.classList.remove('no-scroll')
  } else {
    document.body.classList.add('no-scroll')
  }

  return { isMobileNavOpen: !isMobileNavOpen }
}

const mapStateToProps = createStructuredSelector({
  name: selectFirstName,
  members: selectEnrolledMembers,
  isAuthenticated: selectIsAuthenticated,
  latestMessage: selectNewMessage,
  inboxOpen: selectShowMessagesInbox,
})

export default compose(
  withRouter,
  connect(mapStateToProps, {
    logout: logoutAction,
    changeCurrentMember: changeCurrentMemberAction,
    toggleInbox: showMessagesInbox,
  }),
  withStateHandlers(
    {
      isMobileNavOpen: false,
      newscardShown: false, // ??? not sure if should be false by default
      transitionCard: false,
    },
    {
      toggleMobileNav,
      toggleNewscard: () => bool => ({ newscardShown: bool }),
      toggleCardTransition: () => bool => ({ transitionCard: bool }),
    }
  ),
  lifecycle({
    componentDidUpdate() {
      const {
        location: { pathname },
        inboxOpen,
        toggleInbox,
      } = this.props
      if (pathname !== '/' && inboxOpen) {
        toggleInbox()
      }
    },
  })
)(Header)
